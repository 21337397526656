<template>
    <div class="border-left mx-0 md:mx-20 text-left pl-5">
        <h2>Hi there!</h2>
        <p>
            My name is Chris, and I'm a Test Engineer currently based in Sheffield. I've worked in this area for a fair few years
            now (as the below will hopefully show!), and am still learning every day.
        </p>
        <h3>That's cool and all, but what else do you do?</h3>
        <p>
            Very good question, me writing this section.
        </p>
        <p>
            I'm a serial board game loser, but that doesn't stop me from trying! Chances are, if you want to play a game against me, you're going
            to win. I also play some musical instruments, namely guitar (badly), bass (less badly) and used to play drums. I've been a part of a
            few bands in my past, but currently I'm working on my own stuff as and when I can.
        </p>
        <p>
            I'm also learning Japanese (with an <a href="https://www.jlpt.jp/e/about/levelsummary.html" target="_blank" rel="noopener" class="underline">N4</a> certification), after becoming very interested in the country and some amazing holidays out there, so はじめまして and こんにちは!
        </p>
    </div>
</template>
