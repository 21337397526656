<template>
    <div class="items-center flex flex-wrap justify-evenly pb-2.5 ml-auto mr-auto max-w-lg">
        <div class="max-h-52 max-w-52">
            <img class="rounded-full" src="../../assets/img/profile.jpg" alt="Profile photo" width="200" height="200">
        </div>
        <div >
            <h1>Chris Sherlock</h1>
            <h2>Lead Test Engineer</h2>
            <p class="italic">I have no idea what I'm doing</p>
        </div>
    </div>
</template>
